import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="見つかりませんでした" />
    <p>お探しのページは見つかりませんでした。URLをご確認ください。</p>
  </>
)

export default NotFoundPage
